<template>
  <AccordBlock>
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userModule_UsersPresets'])"></div>
        {{$t('userModule_UsersPresets.localization_value.value')}}
      </div>
    </template>

    <template slot="body">
      <div class="custom-row big-label">
        <div class="custom-col custom-col--20 custom-col--md-50 custom-col--es-100 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_OrderFees'])"></div>
            {{$t('userModule_OrderFees.localization_value.value')}}
          </div>

          <!--<div class="section-label">-->
            <!--<DefaultCheckbox-->
                    <!--:label="'FBM (VIP)'"-->
                    <!--v-model="userPresets.data.FBMFeesCheck"-->

            <!--/>-->
          <!--</div>-->

          <div class="custom-row">
            <div class="custom-col mb-15"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_SkladUSAFees'])"></div>
              <SelectSum
                      class="pt-2"
                      :options="orderFees"
                      :label="$t('userModule_SkladUSAFees.localization_value.value')"
                      :optionsLabel="'cost'"
                      :selected="userPresets.data.FBMFees"
                      :disabled="userPresets.data.FBMFeesByFormula"
                      @change="changeOrderFees"
              />
            </div>
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_OrderFeesByFormula'])"></div>
            <DefaultCheckbox
                :label="$t('userModule_OrderFeesByFormula.localization_value.value')"
                v-model="userPresets.data.FBMFeesByFormula"

            />
          </div>
        </div>

        <div class="custom-col custom-col--20 custom-col--md-50 custom-col--es-100 flex-column">
          <!--<div class="section-label mb-low"-->
               <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
            <!--<div class="admin-edit" @click="editTranslate(['userModule_IWillCreateLabel'])"></div>-->
            <!--{{$t('userModule_IWillCreateLabel.localization_value.value')}}-->
          <!--</div>-->

          <div class="section-label mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_UseEasyPost'])"></div>
            <DefaultCheckbox
                class="easy-post-checkbox"
                    :label="$t('userModule_UseEasyPost.localization_value.value')"
                    v-model="userPresets.data.shipStationCheck"
            />

          </div>

          <div class="custom-row">
            <div class="custom-col mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_Commission'])"></div>
              <SelectSum
                      class="pt-2"
                      :options="shipStation"
                      :label="$t('userModule_Commission.localization_value.value')"
                      :optionsLabel="'cost'"
                      v-bind:class="{'ui-no-valid': userPresets.validation.shipStationFees}"
                      :error="userPresets.validation.shipStationFees"
                      :errorTxt="$t(`${userPresets.validationTranslate.shipStationFees}.localization_value.value`)"
                      :selected="userPresets.data.shipStationFees"
                      :disabled="!userPresets.data.shipStationCheck"
                      @change="changeShipStationFees"
              />
            </div>
            <div class="custom-col mb-0 mt-2"
                 v-if="userPresets.data.shipStationCheck"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_easypost', 'userModule_vipparcel'])"></div>

              <div class="d-flex">
                <RadioDefault
                    class="white-space-line width-initial"
                    :label="$t('userModule_easypost.localization_value.value')"
                    :name="'createType'"
                    :value="userPresets.data.shipStationType === 'easypost'"
                    @click.native="userPresets.data.shipStationType = 'easypost'"
                />
              </div>
              <div class="d-flex">
                <RadioDefault
                    class="white-space-line width-initial mt-1"
                    :label="$t('userModule_vipparcel.localization_value.value')"
                    :name="'createType'"
                    :value="userPresets.data.shipStationType === 'vipparcel'"
                    @click.native="userPresets.data.shipStationType = 'vipparcel'"
                />
              </div>
            </div>


            <div class="custom-col mb-0 mt-2"
                 v-if="userPresets.data.shipStationType === 'vipparcel' && userPresets.data.shipStationCheck"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_useZoneSkipping',])"></div>
              <SwitchCheckbox
                  :label="$t('userModule_useZoneSkipping.localization_value.value')"
                  v-model="userPresets.data.useZoneSkipping"
                  :horizontal="true"
              />
            </div>
          </div>
        </div>

        <div class="custom-col custom-col--20 custom-col--md-50 flex-column">
          <div class="section-label mb-low"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_shipping'])"></div>
            {{$t('userModule_shipping.localization_value.value')}}
<!--            Shipping-->
          </div>

          <div class="custom-row">
            <div class="custom-col mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_fbaAccountType'])"></div>
<!--              FBA account type-->
              <SelectSum
                  class="pt-2"
                  :options="orderFBAFees"
                  :label="$t('userModule_fbaAccountType.localization_value.value')"
                  :optionsLabel="'cost'"
                  :selected="userPresets.data.FBAFees"
                  @change="changeOrderFBAFees"
              />
            </div>
          </div>
        </div>

        <!--<div class="custom-col custom-col&#45;&#45;40 custom-col&#45;&#45;md-100 flex-column">-->
          <!--<div class="section-label mb-low">-->
            <!--Subusers-->
          <!--</div>-->


          <!--<div class="custom-row">-->
            <!--<div class="custom-col mb-0 mt-2">-->
              <!--<DefaultInput-->
                      <!--:label="'Name'"-->
                      <!--v-model="name"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->

          <!--<span class="add-product-btn site-link mt-2"> + Add Product </span>-->
        <!--</div>-->

        <div class="custom-col custom-col--20 custom-col--md-100 flex-column"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_CommissionSkladUSA', 'userModule_CommissionDefault'])"></div>
          <div class="section-label mb-low">
            {{$t('userModule_CommissionSkladUSA.localization_value.value')}}
          </div>


          <div class="custom-row">
            <div class="custom-col mb-0 mt-2">
              <DefaultSelect
                      :options="warehouseCommissions"
                      :label="$t('userModule_CommissionSkladUSA.localization_value.value')"
                      :optionsLabel="'label'"
                      :selected="userPresets.data.warehouseCommission"
                      :clearable="false"
                      @change="changeWarehouseCommissions"
              />
            </div>
          </div>
        </div>

        <div class="custom-col custom-col--20 custom-col--md-100 flex-column"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_orderFeeForManyProducts'])"></div>
          <div class="section-label mb-low">
            {{$t('userModule_orderFeeForManyProducts.localization_value.value')}}
          </div>


          <div class="custom-row">
            <div class="custom-col mb-0 mt-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_orderFeeForManyProductsSelect'])"></div>
              <DefaultSelect
                  class="label-wrap"
                      :options="orderFeeForManyProductsOptions"
                      :label="$t('userModule_orderFeeForManyProductsSelect.localization_value.value')"
                      :optionsLabel="'item_fee'"
                      :selected="userPresets.data.orderFeeForManyProducts ? userPresets.data.orderFeeForManyProducts : defaultFee"
                      @change="changeOrderFeeForManyProducts"
              />
            </div>
          </div>
        </div>



        <div class="custom-col custom-col--20 custom-col--md-100 flex-column"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userModule_oneHourEngravingCost'])"></div>
          <div class="section-label mb-low">
            {{$t('userModule_oneHourEngravingCost.localization_value.value')}}
          </div>


          <div class="custom-row">
            <div class="custom-col mb-0 mt-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['userModule_oneHourEngravingCostDollar'])"></div>

<!--                  v-bind:class="{'ui-no-valid': FBA.validation.orderFee}"-->
<!--                  :errorTxt="$t(`${FBA.validationTranslate.orderFee}.localization_value.value`)"-->
<!--                  :error="FBA.validation.orderFee"-->
              <InputSum
                  :label="$t('userModule_oneHourEngravingCostDollar.localization_value.value')"
                  :icoType="'dollar'"
                  :placeholder="'100'"
                  :numeric="true"
                  v-model="userPresets.data.oneHourEngravingCost"
              />
            </div>
          </div>
        </div>

      </div>
    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../coreComponents/AccordBlock/AccordBlock";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import SelectSum from "../../../../../UI/selectiones/SelectSum/SelectSum";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import InputSum from "@/components/UI/inputs/InputSum/InputSum";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  import SwitchCheckbox from "@/components/UI/checkboxes/SwitchCheckbox/SwitchCheckbox";

  export default {
    name: "UsersPresets",
    components: {
      SwitchCheckbox,
      RadioDefault,
      InputSum,
      DefaultSelect,
      AccordBlock,
      DefaultCheckbox,
      // DefaultInput,
      SelectSum,
    },

    props: {
      userPresets: Object,
      orderFees: Array,
      orderFBAFees: Array,
      shipStation: Array,
    },

    data() {
      return {
        name: 'Ivan Ivanov',

        tooltipAddProductActive: true,

        warehouseCommissions: [
          {label: this.$t('userModule_CommissionDefault.localization_value.value'), value: -1},
          {label: '0%', value: 0},
          {label: '1%', value: 1},
          {label: '2%', value: 2},
          {label: '3%', value: 3},
          {label: '4%', value: 4},
          {label: '5%', value: 5},
          {label: '6%', value: 6},
          {label: '7%', value: 7},
          {label: '8%', value: 8},
          {label: '9%', value: 9},
          {label: '10%', value: 10},
        ],

        orderFeeForManyProductsOptions: [],

        defaultFee: null,

        options: [1, 2, 3],
      }
    },

    mounted() {
      this.$store.dispatch('getOrderItemFees').then(response => {
        this.orderFeeForManyProductsOptions = this.getRespData(response)
        this.defaultFee = this._.find(this.orderFeeForManyProductsOptions, {default: true})
      })
    },


    methods: {
      changeOrderFees(val) {
        this.userPresets.setFBMFees(val)
      },

      changeOrderFBAFees(val) {
        this.userPresets.setFBAFees(val)
      },

      changeShipStationFees(val) {
        this.userPresets.setShipStationFees(val)
      },

      changeWarehouseCommissions(val) {
        this.userPresets.setWarehouseCommission(val)
      },

      changeOrderFeeForManyProducts(val) {
        this.userPresets.setOrderFeeForManyProducts(val)
      },
    }


  }
</script>

<style scoped>
.label-wrap >>> .v-select__label{
  white-space: initial;
  top: initial;
  bottom: 34px;
}

.big-label .section-label{
  height: 35px;
}

@media (min-width: 1220px) and (max-width: 1480px) {
  .easy-post-checkbox >>> .default-checkbox__label {
    font-size: 13px;
  }

  .big-label .section-label{
    height: 42px;
  }
}

@media (min-width: 1550px) and (max-width: 1680px) {
  .easy-post-checkbox >>> .default-checkbox__label {
    font-size: 13px;
  }

  .big-label .section-label{
    height: 42px;
  }
}


</style>
