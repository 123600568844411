<template>
  <modal
          @close="close"
          class="custom-popup-modal custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_ChangeAddress'])"></div>
        {{$t(`common_ChangeAddress.localization_value.value`)}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': user.validation.userAddress}"
                    :error="user.validation.userAddress"
                    :errorTxt="$t(`${user.validationTranslate.userAddress}.localization_value.value`)"
                    :label="$t('common_address.localization_value.value')"
                    :type="'text'"
                    v-model="user.userAddress"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': user.validation.userCity}"
                    :error="user.validation.userCity"
                    :errorTxt="$t(`${user.validationTranslate.userCity}.localization_value.value`)"
                    :label="$t('common_city.localization_value.value')"
                    :type="'text'"
                    v-model="user.userCity"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col-md--100"
               v-if="_.has(user.userCountry, 'id') && user.userCountry.id === RUSSIA_COUNTRY_ID"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cityCyrillic'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': user.validation.userAddressCyrilic}"
                    :error="user.validation.userAddressCyrilic"
                    :errorTxt="$t(`${user.validationTranslate.userAddressCyrilic}.localization_value.value`)"
                    :label="$t('common_cityCyrillic.localization_value.value')"
                    :type="'text'"
                    v-model="user.userAddressCyrilic"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
            <DefaultSelect
                    v-bind:class="{'ui-no-valid': user.validation.userCountry}"
                    :error="user.validation.userCountry"
                    :errorTxt="$t(`${user.validationTranslate.userCountry}.localization_value.value`)"
                    :label="$t('common_country.localization_value.value')"
                    :options="options"
                    :selected="user.userCountry"
                    :optionsLabel="'label'"
                    @change="changeCountry"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
            <DefaultSelect
                    v-bind:class="{'ui-no-valid': user.validation.userRegion}"
                    :options="optionsDistrict"
                    :error="user.validation.userRegion"
                    :errorTxt="$t(`${user.validationTranslate.userRegion}.localization_value.value`)"
                    :label="$t('common_regions.localization_value.value')"
                    :selected="user.userRegion"
                    :optionsLabel="'label'"
                    @change="changeDistrict"
            />
          </div>

          <div class="custom-col custom-col--50 custom-col-md--100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': user.validation.userZip}"
                    :error="user.validation.userZip"
                    :errorTxt="$t(`${user.validationTranslate.userZip}.localization_value.value`)"
                    :label="$t('common_postNumber.localization_value.value')"
                    v-model="user.userZip"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col-md--100"
               v-if="user.userCountry.country_group && user.userCountry.country_group.fromSNGGroup"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['userModule_ITNCode'])"></div>
            <DefaultInput
                    v-bind:class="{'ui-no-valid': user.validation.userITN}"
                    :error="user.validation.userITN"
                    :errorTxt="$t(`${user.validationTranslate.userITN}.localization_value.value`)"
                    :label="$t('userModule_ITNCode.localization_value.value')"
                    v-model="user.userITN"
            />
          </div>
          <div class="custom-col custom-col--100" v-if="!user.userAlternativeAddress"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_alternative_address_button'])"></div>
            <button class="main-button alternative-address-btn" v-on:click="showAdditionalAddressForm = !showAdditionalAddressForm">
              {{ $t('common_alternative_address_button.localization_value.value') }}
            </button>
          </div>
        </div>

        <div v-if="showAdditionalAddressForm || user.userAlternativeAddress">
          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate(['common_alternative_address', 'common_alternative_address_button'])"></div>
            {{ $t('common_alternative_address.localization_value.value') }}
          </div>
          <div class="custom-row">
            <div class="custom-col custom-col--50 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': user.validation.userAlternativeAddress}"
                  :error="user.validation.userAlternativeAddress"
                  :errorTxt="$t(`${user.validationTranslate.userAlternativeAddress}.localization_value.value`)"
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  v-model="user.userAlternativeAddress"
              />
            </div>
            <div class="custom-col custom-col--50 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': user.validation.userAlternativeCity}"
                  :error="user.validation.userAlternativeCity"
                  :errorTxt="$t(`${user.validationTranslate.userAlternativeCity}.localization_value.value`)"
                  :label="$t('common_city.localization_value.value')"
                  :type="'text'"
                  v-model="user.userAlternativeCity"
              />
            </div>
            <div class="custom-col custom-col--50 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
              <DefaultSelect
                  v-bind:class="{'ui-no-valid': user.validation.userAlternativeCountry}"
                  :error="user.validation.userAlternativeCountry"
                  :errorTxt="$t(`${user.validationTranslate.userAlternativeCountry}.localization_value.value`)"
                  :label="$t('common_country.localization_value.value')"
                  :options="optionsAlternativeCountry"
                  :selected="user.userAlternativeCountry"
                  :optionsLabel="'label'"
                  @change="changeAlternativeCountry"
              />
            </div>

            <div class="custom-col custom-col--50 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_regions'])"></div>
              <DefaultSelect
                  v-bind:class="{'ui-no-valid': user.validation.userAlternativeRegion}"
                  :options="optionsAlternativeDistrict"
                  :error="user.validation.userAlternativeRegion"
                  :errorTxt="$t(`${user.validationTranslate.userAlternativeRegion}.localization_value.value`)"
                  :label="$t('common_regions.localization_value.value')"
                  :selected="user.userAlternativeRegion"
                  :optionsLabel="'label'"
                  @change="changeAlternativeDistrict"
              />
            </div>

            <div class="custom-col custom-col--50 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_postNumber'])"></div>
              <DefaultInput
                  v-bind:class="{'ui-no-valid': user.validation.userAlternativeZip}"
                  :error="user.validation.userAlternativeZip"
                  :errorTxt="$t(`${user.validationTranslate.userAlternativeZip}.localization_value.value`)"
                  :label="$t('common_postNumber.localization_value.value')"
                  v-model="user.userAlternativeZip"
              />
            </div>
            <div class="custom-col custom-col--100 custom-col-md--100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_use_alternative_address'])"></div>
              <DefaultCheckbox
                  :label="$t('common_use_alternative_address.localization_value.value')"
                  :value="useUserAlternativeAddress"
                  @change.native="useUserAlternativeAddress = !useUserAlternativeAddress"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_save'])"></div>
        <span
                class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
                @click="close"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <MainButton
                class="custom-popup__btn-i "
                v-bind:class="{'disabled-btn' : $store.getters.getProfileBtn}"
                :value="$t('common_save.localization_value.value')"
                @click.native="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {countriesMixin} from "../../../../../mixins/commonMixins/countriesMixin";
  import {POLAND_COUNTRY_ID, RUSSIA_COUNTRY_ID} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChangeAddressPopup",
    components: {
      DefaultSelect,
      Modal,
      MainButton,
      DefaultInput,
      DefaultCheckbox,
    },

    mixins: [countriesMixin],

    props: {
      user: Object,
    },

    watch: {
      user() {
        this.setCountry()
      }
    },

    data() {
      return {
        showAdditionalAddressForm: false,
        options: [],
        optionsDistrict: [],
        optionsAlternativeCountry: [],
        optionsAlternativeDistrict: [],
        useUserAlternativeAddress: 0,

        RUSSIA_COUNTRY_ID: RUSSIA_COUNTRY_ID,
      }
    },

    mounted() {
      if (Object.keys(this.user.user).length > 0) {
        this.setCountry()
        this.setAlternativeCountry()
      }
      this.useUserAlternativeAddress = this.user.user.use_alternative_address
    },

    methods: {

      close() {
        this.$emit('reload')
        this.$emit('close')
      },

      checkAlternativeAddress(user) {
        return Object.keys(user).some((key) => {
          if(key.indexOf('userAlternative') !== -1 && user[key]) {
            return true;
          }
        })
      },

      save() {
        let data = {}

        if (!this.user.userValidation({
          userAddress: true,
          userCity: true,
          userCountry: true,
          userRegion: true,
          userZip: true,
          userITN: this.user.getUserCountry()?.country_group?.fromSNGGroup,
        })) {
          return
        }
        let dataAlternativeContacts = {}
        if (this.checkAlternativeAddress(this.user) && this.useUserAlternativeAddress) {
          if (!this.user.userValidation({
            userAlternativeAddress: true,
            userAlternativeCity: true,
            userAlternativeCountry: true,
            userAlternativeRegion: true,
            userAlternativeZip: true,
          })) {
            return
          }
        }
        dataAlternativeContacts = this.user.prepareAlternativeAddressSave()
        let dataContacts = this.user.prepareSave()
        delete dataContacts['itn_number']



        data = {
          user_id: this.user.prepareSave().user_id,
          itn_number: this.user.prepareSave().itn_number,
          contacts: dataContacts,
          alternative_contacts: dataAlternativeContacts,
          use_alternative_address: this.useUserAlternativeAddress
        }

        this.$store.dispatch('changeProfileAddressWithoutPermission', {id: this.user.getUserId(), data: data}).then(response => {
          if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

              this.openNotify('success', 'common_notificationRecordChanged')
              this.$emit('reload')
              this.$emit('close')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      setCountry() {
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries);
          this.options = this.getCountriesForRegistration(countries)

          this.$store.dispatch('getRegionsByCountry', this.user.userCountry.id).then(() => {
            this.optionsDistrict = this._.toArray(this.$store.getters.getRegions)
          })

        })
      },

      setAlternativeCountry() {
        this.$store.dispatch('fetchCountries').then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          let polandCountry = this._.find(countries, {id: POLAND_COUNTRY_ID})

          this.optionsAlternativeCountry = [polandCountry]

          if(this.user.userAlternativeCountry?.id) {
            this.$store.dispatch('getRegionsByCountry', this.user.userAlternativeCountry.id).then(() => {
              this.optionsAlternativeDistrict = this._.toArray(this.$store.getters.getRegions)
            })
          }

        })
      },

      changeCountry(val) {
        if(!val) {
          this.changeDistrict('')
          return false;
        }
        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsDistrict = this._.toArray(this.$store.getters.getRegions);
          this.user.setUserCountry(val)
          this.changeDistrict('')
          // this.stateDistrict = {
          //   id: this.data.user_personal_contact.region.id,
          //   label :this.data.user_personal_contact.region.name,
          // };
        });
      },

      changeDistrict(val) {
        this.user.setUserRegion(val)
      },

      changeAlternativeCountry(val) {
        if(!val) {
          this.changeAlternativeDistrict('')
          return false;
        }
        this.$store.dispatch('getRegionsByCountry', val.id).then(() => {
          this.optionsAlternativeDistrict = this._.toArray(this.$store.getters.getRegions);
          this.user.setUserAlternativeCountry(val)
          this.changeAlternativeDistrict('')
        });
      },

      changeAlternativeDistrict(val) {
        this.user.setUserAlternativeRegion(val)
      }
    }
  }
</script>

<style scoped>
  .alternative-address-btn {
    max-width: max-content;
    font-size: 14px;
  }
</style>
